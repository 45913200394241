<template>
  <div class="page" ref="container" @mousewheel="mouseWheelHandle">
    <div class="header">
      <div class="header-container" flex="cross:center">
        <div class="header-logo">
          <img src="//static.lianhaikeji.com/images/20220525/a1a96260bb354018bc7dc8764ca52832.png" alt="" class="logo">
        </div>
        <div class="nav" flex>
          <div class="nav-item" @click="change(item,index)" :class="{'active': index == activeIndex}"
               v-for="(item,index) in navList" :key="index">
            {{item.name}}
          </div>
        </div>
      </div>
    </div>
    <div class="page-container" >
      <!--首页-->
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <section ref="index" id="index" class="index">
          <img src="//static.lianhaikeji.com/images/20220525/f4c103e1c609441fba1aa111c5fb33e1.png" alt=""
               class="index-img">
          <div class="statics" flex>
            <div class="statics-item" :style="{'background-image': `url(${item.img})`}" v-for="(item,index) in statics"
                 :key="index">
              <div class="statics-txt">
                <countTo :startVal="item.startNum" :endVal="item.endNum" :duration="3000"></countTo>{{item.unit||''}}+
              </div>
            </div>
          </div>
        </section>
      </transition>
      <!--金融解决方案-->
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <section ref="financial" id="financial" class="financial">
          <img src="//static.lianhaikeji.com/images/20220525/88e35475a36d4c0fa2e56a017348ae89.png" alt=""
               class="financial-img">
        </section>
      </transition>
      <!--产品服务-->
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <section ref="product" id="product" class="product">
          <img src="//static.lianhaikeji.com/images/20220525/3fdf2466f8ab4d1bbc8c5450f6e2ec3f.png" alt=""
               class="product-title">
          <div class="product-wrap">
            <transition-group name="list-complete" tag="div" class="product-wrap-layer">
              <div class="product-item list-complete-item" v-for="(item) in productList" :key="item.id">
                <img :src="item.icon" alt="" class="product-icon">
              </div>
            </transition-group>
            <transition-group name="bg-complete" tag="div" class="product-wrap-bg">
              <div class="product-item bg-complete-item" v-for="(item) in productList" :key="item.id+'txt'">
                <img :src="item.txt" alt="" class="product-txt">
              </div>
            </transition-group>
          </div>
        </section>
      </transition>
      <!--服务客户-->
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <section ref="customer" id="customer" class="customer">
          <img src="//static.lianhaikeji.com/images/20220525/65944417b77d4044926bd71005450570.png" alt=""
               class="main-img">
        </section>
      </transition>
      <!--联系我们-->
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <section ref="us" id="us" class="customer">
          <img src="//static.lianhaikeji.com/images/20220525/37a6ca4e1c7544728566621b27e5fca1_modi.png" alt=""
               class="main-img">
        </section>
      </transition>
      <footer>
        <p data-v-c0049ef0=""> © 2009-2022 lianhaikeji.com
          版权所有ICP证:
          <a data-v-c0049ef0="" href="https://beian.miit.gov.cn/" target="_blank">浙B2-20170379-3</a></p>
      </footer>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'
export default {
  name: 'HomeView',
  components: {
    countTo
  },
  mounted () {
    this.initTweenMax()
  },
  data () {
    return {
      activeIndex: 0,
      activeMenu: {
        name: '首页',
        id: 'index'
      },
      navList: [{
        name: '首页',
        id: 'index'
      }, {
        name: '金融行业解决方案',
        id: 'financial'
      }, {
        name: '产品服务',
        id: 'product'
      }, {
        name: '服务客户',
        id: 'customer'
      }, {
        name: '联系我们',
        id: 'us'
      }],
      statics: [{
        name: '金融机构',
        img: '//static.lianhaikeji.com/images/20220525/c731e0453c5242ab9ed12e3d86a35b11.png',
        startNum: 0,
        endNum: 400
      }, {
        name: '分支机构',
        img: '//static.lianhaikeji.com/images/20220525/06ca21d041c148f381c71c8391b935fe.png',
        startNum: 0,
        endNum: 1000
      }, {
        name: '金融用户',
        img: '//static.lianhaikeji.com/images/20220525/c3ce167e466547338a00dac7107ffac4.png',
        startNum: 0,
        endNum: 1,
        unit: '亿'
      }, {
        name: '权益商品',
        img: '//static.lianhaikeji.com/images/20220525/0ef37dffdd594813a104b57386fb21c3.png',
        startNum: 0,
        endNum: 1,
        unit: '亿'
      }, {
        name: '营销活动',
        img: '//static.lianhaikeji.com/images/20220525/de5c14f970e443d8ae270b0cd85a4e99.png',
        startNum: 0,
        endNum: 5000
      }, {
        name: '客户经理',
        img: '//static.lianhaikeji.com/images/20220525/c731e0453c5242ab9ed12e3d86a35b11.png',
        startNum: 0,
        endNum: 1000
      }],
      productList: [],
      tempList: [],
      timer: null,
      isScrolling: false,
      fullpage: {
        deltaY: 0
      }
    }
  },
  methods: {
    initTweenMax () {
      const TweenMax = 'Tween_Max'
      let script = document.getElementById(TweenMax)
      if (script) {
        this.listenScript(script)
      } else {
        script = document.createElement('script')
        script.setAttribute('src', 'https://static.lianhaikeji.com/lib/js/TweenMax.min.js')
        script.setAttribute('id', TweenMax)
        this.listenScript(script)
        document.body.appendChild(script)
      }
    },
    listenScript (script) {
      script.addEventListener('load', () => {
        this.TweenMax = window.TweenMax
      })
    },
    mouseWheelHandle (event) { // 监听鼠标监听
      // 添加冒泡阻止
      const evt = event || window.event
      if (evt.stopPropagation) {
        evt.stopPropagation()
      } else {
        evt.returnValue = false
      }
      if (this.isScrolling) { // 判断是否可以滚动
        return false
      }
      const e = event.originalEvent || event
      this.fullpage.deltaY = e.deltaY || e.detail // Firefox使用detail
      if (this.fullpage.deltaY > 0) {
        this.next()
      } else if (this.fullpage.deltaY < 0) {
        this.pre()
      }
    },
    next () { // 往下切换
      let activeMenu = {}
      let activeIndex = 0
      if (this.activeIndex < 3) {
        activeIndex = this.activeIndex + 1
        activeMenu = this.navList[activeIndex]
        this.change(activeMenu, activeIndex)
      }
    },
    pre () { // 往上切换
      let activeMenu = {}
      let activeIndex = 0
      if (this.activeIndex > 0) {
        activeIndex = this.activeIndex - 1
        activeMenu = this.navList[activeIndex]
        this.change(activeMenu, activeIndex)
      }
    },
    change (item, index) {
      this.activeIndex = index
      this.activeMenu = item
      const offsetTop = this.$refs[item.id].offsetTop
      this.isScrolling = true
      const self = this
      window.TweenMax.to(this.$refs.container, 1.5, {
        scrollTop: offsetTop,
        ease: window.Power4.ease,
        onComplete: () => {
          self.isScrolling = false
        }
      })
      if (index == 2) {
        const tempList = [{
          icon: '//static.lianhaikeji.com/images/20220525/fd407453de9749379fedfccd73212f7f.png',
          txt: '//static.lianhaikeji.com/images/20220525/1b0eb9d7b1da434b8268cb00c753c251.png',
          id: 1
        }, {
          icon: '//static.lianhaikeji.com/images/20220525/a59509f2c01a41be80209c144b0032ae.png',
          txt: '//static.lianhaikeji.com/images/20220525/3ba019f5c781407791c37154b2a2e967.png',
          id: 2
        }, {
          icon: '//static.lianhaikeji.com/images/20220525/47d29eb96417427c8ce8fb40aa59e2db.png',
          txt: '//static.lianhaikeji.com/images/20220525/e23a5ee049fb4a6685b3105d594baa8e.png',
          id: 3
        }]
        // this.productList = []
        if (!this.productList.length) {
          this.timer = setInterval(() => {
            if (tempList.length) {
              this.productList.push(tempList.shift())
            } else {
              clearInterval(this.timer)
              this.timer = null
            }
          }, 400)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .page {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    &-container {
    }
  }

  .logo {
    width: 170px;
    height: 51px;
  }

  .header {
    box-shadow: 0px 25px 26px 0px rgba(69, 102, 197, 0.22);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 100;
    &-logo {
      flex: 1;
    }

    &-container {
      width: 1100px;
      margin: 0 auto;
    }
  }

  .nav {
    &-item {
      color: #16307B;
      font-size: 16px;
      font-weight: bold;
      padding: 30px;
      /*padding: 22px 30px 28px 30px;*/
      position: relative;
      cursor: pointer;

      &::after {
        content: "";
        width: 0;
        height: 3px;
        border-radius: 3px;
        background-color: #FBB03B;
        position: absolute;
        left: 100%;
        bottom: 0;
        transition: all .4s;
      }

      &.active {
        color: #FBB03B;

        &::after {
          width: 100%;
          left: 0;
          transition-delay: 0.1s;
        }

        & ~ li::after {
          left: 0;
        }
      }
    }
  }

  section {
    .main-img {
      width: 100%;
    }

    .financial-img {
      width: 1516px;
      display: block;
      margin: 0 auto;
    }
  }
  .index{
    &-img{
      width: 100%;
    }
  }

  .financial{
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .statics {
    width: 1200px;
    margin: 40px auto;

    &-item {
      background-size: 100% 100%;
      width: 132px;
      height: 169px;
      flex: 0 0 132px;
      margin: 0 30px;
      position: relative;
    }
    &-txt{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      color: #16307B;
      font-size: 32px;
      font-weight: bold;
      white-space: nowrap;
      margin-top: 8px;
    }
  }

  .product {
    padding-top: 140px;
    text-align: center;
    position: relative;
    height: 1000px;
    &-title {
      height: 41px;
      width: 210px;
    }

    &-wrap {
      width: 1200px;
      margin: 30px auto 0;
      &-layer{
        display: flex;
        position: absolute;
        z-index: 10;
      }
      &-bg{
        display: flex;
        position: absolute;
        top: 500px;
        padding-left: 15px;
      }
    }

    &-item {
      width: 412px;
      position: relative;
    }

    &-icon {
      width: 412px;
    }

    &-txt {
      width: 370px;
      display: block;
    }
  }

  .customer{
    padding-top: 100px;
  }
  .list-complete-item {
    transition: all 1.5s;
    display: inline-block;
    margin-right: 10px;
  }
  .list-complete-enter, .list-complete-leave-to
    /* .list-complete-leave-active for below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(-30px);
  }
  .list-complete-leave-active {
    position: absolute;
  }

  .bg-complete-item {
    transition: all 2s;
    display: inline-block;
    margin-right: 10px;
    height: 369px;
    overflow: hidden;
  }
  .bg-complete-enter, .bg-complete-leave-to
    /* .list-complete-leave-active for below version 2.1.8 */ {
    height: 0;
    transform: translateY(30px);
  }
  .bg-complete-leave-active {
    position: absolute;
  }
  footer{
    padding: 30px;
    background: #f2f2f2;
    text-align: center;
  }
</style>
